import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import BodyCopy from '@root/gatsby-contentful-core/src/components/body-copy';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { Eyebrow, H1Display, H3 } from '@root/gatsby-contentful-core/src/components/text';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { VERTICAL_IMAGE_ALIGNMENT } from '@root/gatsby-contentful-core/src/constants/constants';

export default function HeroImageRight({
  anchor,
  buttons,
  eyebrow,
  eyebrowImage,
  eyebrowImageAltText,
  form,
  gatsbyImage,
  imageAlt,
  headline,
  styleProps,
  subhead,
  body,
  backgroundColor = BackgroundColors.cmsValues.WHITE,
  hideImageOnMobile = false,
  verticalImageAlignment,
  sectionSpacing = {},
}) {
  const renderButtons = buttons?.length > 0;

  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      css={[
        stylesBase.heroSection,
        BackgroundColors.getBackgroundStyle(backgroundColor),
        hideImageOnMobile && stylesHideImageOnMobile.heroSection,
        verticalImageAlignment === VERTICAL_IMAGE_ALIGNMENT.BOTTOM && imageAlignBottom.heroSection,
        sectionSpacingStyles,
        styleProps,
      ]}
      id={anchor}
    >
      <div className={'wrap'}>
        <div className={'container_content'}>
          {eyebrowImage && (
            <GatsbyImage
              alt={eyebrowImageAltText ?? ''}
              className={'eyebrow_image'}
              image={getImage(eyebrowImage)}
              imgStyle={{ objectFit: 'contain' }}
            />
          )}
          {eyebrow && <Eyebrow className={'eyebrow'}>{eyebrow}</Eyebrow>}
          {headline && <H1Display className={'headline'}>{headline}</H1Display>}
          {subhead && <H3 className={'subhead'}>{subhead}</H3>}
          <BodyCopy
            className={'body'}
            content={body}
          />
          {renderButtons && (
            <div className={'container_cta'}>
              {buttons.map((button, i) => {
                const ButtonComponent = button;
                return <ButtonComponent key={i} />;
              })}
            </div>
          )}
          {form}
        </div>

        <div className={'container_image'}>
          <GatsbyImage
            alt={imageAlt ?? ''}
            image={getImage(gatsbyImage)}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: verticalImageAlignment === VERTICAL_IMAGE_ALIGNMENT.BOTTOM ? 'bottom' : '50% 50%',
            }}
            style={{
              height: '100%',
              width: '100%',
            }}
          />
        </div>
      </div>
    </section>
  );
}

HeroImageRight.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(BackgroundColors.cmsValueList),
  body: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  buttons: PropTypes.arrayOf(PropTypes.func),
  eyebrow: PropTypes.string,
  eyebrowImage: PropTypes.object,
  eyebrowImageAltText: PropTypes.string,
  form: PropTypes.node,
  gatsbyImage: PropTypes.object.isRequired,
  headline: PropTypes.string.isRequired,
  hideImageOnMobile: PropTypes.bool,
  imageAlt: PropTypes.string,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  styleProps: PropTypes.object,
  subhead: PropTypes.string,
  verticalImageAlignment: PropTypes.string,
};

const stylesHideImageOnMobile = StyleSheet.create({
  heroSection: {
    '.wrap': {
      '.container_content': {
        padding: '80px 20px',

        ...Responsive.lg({
          padding: 0,
        }),
      },

      '.container_image': {
        display: 'none',

        ...Responsive.lg({
          display: 'flex',
        }),
      },
    },
  },
});

const imageAlignBottom = StyleSheet.create({
  heroSection: {
    '.wrap .container_image': {
      ...Responsive.md({
        alignItems: 'flex-end',
      }),
    },
  },
});

const stylesBase = StyleSheet.create({
  heroSection: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',

    '.wrap': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: 1200,

      ...Responsive.lg({
        position: 'relative',
        flexDirection: 'row',
        margin: '0 0 0 30px',
        minHeight: 650,
        maxHeight: 700,
      }),

      '.container_content': {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'center',
        textAlign: 'center',
        padding: '80px 20px 20px',

        ...Responsive.lg({
          position: 'absolute',
          zIndex: 2,
          top: 0,
          left: 0,
          width: '45%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          textAlign: 'left',
          padding: 0,
        }),

        '.eyebrow_image': {
          width: '100%',
          height: 50,
          marginBottom: 16,

          '& img': {
            objectPosition: 'center !important',

            ...Responsive.lg({
              objectPosition: 'left !important',
            }),
          },
        },

        '.eyebrow': {
          maxWidth: 350,
        },

        '.headline, .subhead': {
          maxWidth: 550,

          ...Responsive.md({
            maxWidth: 800,
          }),
        },

        '.subhead': {
          color: Colors.gray50(),
          fontWeight: 400,
        },

        '.body': {
          maxWidth: 450,
        },

        '.eyebrow, .headline, .subhead, .body': {
          marginBottom: 12,
        },

        '.container_cta': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: 30,
          width: '100%',
          maxWidth: 260,

          '& > a + a': {
            margin: '16px 0 0 0',
          },

          ...Responsive.md({
            flexDirection: 'row',
            maxWidth: 480,

            '& > a + a': {
              margin: '0 0 0 16px',
            },
          }),

          ...Responsive.lg({
            justifyContent: 'flex-start',
          }),
        },
      },

      '.container_image': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        maxHeight: 700,

        ...Responsive.lg({
          margin: '0 0 0 35%',
          height: '100%',
        }),
      },
    },
  },
});
